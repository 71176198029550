<style scoped>
    #email-attachments-container {
        margin: 10px;
    }

    .email-attachment-link {
        font-size: 12px;
        margin-right: 15px;
        text-decoration: none;
    }

    a.email-attachment-link, a.email-attachment-link:visited, a.email-attachment-link:hover, a.email-attachment-link:active {
        color: #0D3B70;
    }

    #email-attachment-text {
        text-decoration: underline;
        margin-right: 10px;
    }
</style>

<template>
    <Aggrid
        apiRoute='email/list'
        :apiParams="filter"
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :context="context"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher un email" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <MailReader
        v-show="isModalVisible"
        :html-content="email?.message"
        :attachments="email?.attachments"
        :recipients="email?.to.split(',')"
        :read-only="true"
        @close-modal="isModalVisible = false"
    />
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListEmailsActionButtonsRenderer from './ListEmailsActionButtonsRenderer'
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer'
    import Modal from '../../components/App/Modal'
    import config from '../../config'
    import MailReader from "@/components/App/MailReader.vue";

    export default {
        name: 'Emails',
        components: {
            MailReader,
            BaseButton,
            BaseInput,
            Aggrid,
            ListEmailsActionButtonsRenderer,
            ListTextWithIconRenderer,
            Modal
        },
        data() {
            return {
                columnDefs: [
                    { field: 'date', headerName : `Date`, lockPosition: true, filter: 'agDateColumnFilter' },
                    { field: 'from', headerName : `De`, lockPosition: true },
                    { field: 'to', headerName : `À`, lockPosition: true, width: 300, cellRendererFunctionName: 'ToRendererFunction', suppressMenu: true },
                    { field: 'cc', headerName : `Copie`, lockPosition: true, cellRendererFunctionName: 'CopyRendererFunction', suppressMenu: true },
                    { field: 'subject', headerName : `Sujet`, flex: 1, lockPosition: true },
                    { field: 'messageShort', headerName : `Message`, flex: 2, lockPosition: true },
                    { headerName : 'Pièce(s) jointe(s)', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: function(param) {
                        return {
                            computedValue: param?.data?.attachments.length > 0,
                            mode: 'boolean'
                        }
                    }},
                    { headerName : '', width: 220, cellRenderer: 'ListEmailsActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListEmailsActionButtonsRenderer: ListEmailsActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'ToRendererFunction',
                        function(param) {
                            let emails = '';
                            let to = param?.data?.to.split(',');
                            for (let email in to) {
                                if (emails.length > 0) emails += '<br/>';
                                emails += to[email];
                            }
                            return emails;
                        }
                    ],[
                        'CopyRendererFunction',
                        function(param) {
                            let emails = '';
                            let cc = param?.data?.cc.split(',');
                            for (let email in cc) {
                                if (emails.length > 0) emails += '<br/>';
                                emails += cc[email];
                            }
                            return emails;
                        }
                    ]
                ]),
                context: null,
                displayModalConsultEmail: false,
                isModalVisible: false,
                email: null,
                config: config,
                filter: {
                    search: ''
                },
                search: '',
                searchTimeout: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        methods: {
            closeModal() {
                this.displayModalConsultEmail = false;
                this.email = null;
            },
            openModal(email) {
                this.email = email;
                this.isModalVisible = true;
            },
            onSearchInputChange(input) {
                if (this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }
                
                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)   
            },
        }
    }
</script>